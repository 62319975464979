pre {
  margin: 4px 0 !important;
}

.blockquote {
  border-left: 5px solid var(--text-color-lightest);
  font-style: italic;
  border-radius: 4px;
  margin: 7px 0;
  padding: 7px 14px;
  background: var(--background-color-lighter)
}

.codeBlock {
  font-family: Monaco,Menlo,Consolas,Courier New,monospace!important;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  tab-size: 4;
  padding: 8px;
  border: 1px solid var(--background-color) ;
  border-radius: 4px;
  background: var(--background-color-lighter);
}
