.imgContainer {
    position: relative;
    margin: 10px;
    max-width: 300px;
    max-height: 500px;
    top: -40px;
    left: 30px;
}

.imgOverlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.imgBtn {
    background-color: rgba(0, 0, 0, 0.623);
    color: #fff;
    /* padding: 1em;
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 1em;
    font-weight: bold; */
    /* margin: 0.33em; */
}

.imgContainer:hover .imgOverlay {
    opacity: 0.9;
}

.imgThumbnail {
    max-width: 300px;
    max-height: 500px;
}